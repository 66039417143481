import { InputSwitch } from 'primereact/inputswitch';
import { useState } from 'react';
import { Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import LuckyCalImage from '../../images/events/LuckyNumberCalendar.jpg';
import LuckyNumbersService from '../../services/LuckyNumbersService';
import { LuckyNumber } from './models/luckynumber';

// import { PaymentForm } from 'react-square-web-payments-sdk';
// import { TokenResult, VerifyBuyerResponseDetails } from '@square/web-payments-sdk-types';

function PurchaseLuckyCalendarPage() {

  const luckyNumberService = new LuckyNumbersService();
  const calendarPrice: number = 30;
  const deliveryFeePrice: number = 1.50;
  const cardFeePrice: number = 1.39;
  const locationId: string = "LE2V0BFCDP555";
  const squareApplicationId: string = "sq0idp-5FpPZjkH46_jdCHin_3Nyw";
  //const squareApplicationId: string = "sandbox-sq0idb-Eu1pEUNP150iIwdcRhSL4A";

  //Get Query String Parms
  const getQueryParams = (s?: string): Map<string, string> => {
    if (!s || typeof s !== 'string' || s.length < 2) {
      return new Map();
    }

    const a: [string, string][] = s
      .substr(1) // remove `?`
      .split('&') // split by `&`
      .map(x => {
        const a = x.split('=');
        return [a[0], a[1]];
      }); // split by `=`

    return new Map(a);
  };
  const s = useLocation().search;
  const queryparms = getQueryParams(s);
  const passedPaymentType = queryparms?.get("type");

  const [validated, setValidated] = useState(false);
  const [luckyNumberRecords, setLuckyNumberRecords] = useState<any[]>([new LuckyNumber(1)]);
  const [calendarId, setCalendarId] = useState<string>("");
  const [knightAssigned, setKnightAssigned] = useState<string>(passedPaymentType?.startsWith("Knight-Event") ? "Knight-Event" : "");
  const [cashType, setCashType] = useState<string>(passedPaymentType?.startsWith("Knight") ? "Cash" : "");

  const [paymentType, setPaymentType] = useState<string>(passedPaymentType || "Online");
  const [quantity, setQuantity] = useState<number>(1);
  const [coverFees, setCoverFees] = useState<boolean>(false);
  const [deliverToOwner1, setDeliverToOwner1] = useState<boolean>(false);
  const [deliveryFees, setDeliveryFees] = useState<number>(0.00);
  const [totalPayment, setTotalPayment] = useState<number>(0.00);

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModlaMessage] = useState("");

  const handleClose = () => setShowModal(false);

  const [firstNameValid, setFirstNameValid] = useState<any>(null);
  const [lastNameValid, setLastNameValid] = useState<any>(null);
  const [emailValid, setEmailValid] = useState<any>(null);
  const [mobilePhoneValid, setMobilePhoneValid] = useState<any>(null);
  const [streetAddressValid, setStreetAddressValid] = useState<any>(null);
  const [cityValid, setCityValid] = useState<any>(null);
  const [stateValid, setStateValid] = useState<any>(true);
  const [postalCodeValid, setPostalCodeValid] = useState<any>(null);
  const [knightAssignedValid, setKnightAssignedValid] = useState<any>(true);
  const [calendarIdValid, setCalendarIdValid] = useState<any>(true);

  const knightPaymentType = ((paymentType === "Knight-Card" || paymentType === "Knight-Cash" || paymentType === "Knight-Event"));
  const isFormValid = firstNameValid && lastNameValid && emailValid && mobilePhoneValid && streetAddressValid && cityValid && stateValid && postalCodeValid;

  const updateItem = (updatedItem: any) => {
    setLuckyNumberRecords(luckyNumberRecords => luckyNumberRecords.map((item: any) =>
      item.key === updatedItem.key ? updatedItem : item
    ));
  };

  const copyAddress = (fromIndex: number, toIndex: number) => {
    const toRec: LuckyNumber = luckyNumberRecords[toIndex];
    toRec.lastName = luckyNumberRecords[fromIndex].lastName;
    toRec.emailAddress = luckyNumberRecords[fromIndex].emailAddress;
    toRec.mobilePhone = luckyNumberRecords[fromIndex].mobilePhone;
    toRec.streetAddress = luckyNumberRecords[fromIndex].streetAddress;
    toRec.city = luckyNumberRecords[fromIndex].city;
    toRec.state = luckyNumberRecords[fromIndex].state;
    toRec.postalCode = luckyNumberRecords[fromIndex].postalCode;
    updateItem(toRec);
  };

  const calcPayment = (passedQuantity: number = quantity, passedCoverCardFee: boolean = coverFees, passedPaymentType: string = paymentType, passedDeliverToOwner1: boolean = deliverToOwner1) => {
    const outside43015Count: number = knightPaymentType ? 0 : luckyNumberRecords.filter((i: LuckyNumber) => !i.postalCode || i.postalCode?.trim()?.startsWith("43015") ? 0 : 1)?.length || 0;
    const calcDeliveryFees: number = (passedDeliverToOwner1 === true || passedPaymentType?.startsWith("Knight-")) ? 0 : deliveryFeePrice * outside43015Count;

    setDeliveryFees(calcDeliveryFees);
    setCoverFees(passedCoverCardFee);
    setDeliverToOwner1(passedDeliverToOwner1);
    const total: number = (calendarPrice * passedQuantity) + calcDeliveryFees + (passedCoverCardFee ? cardFeePrice : 0);
    setTotalPayment(total);
  };


  const populatePurchaseRequest = (paymentInfo: any = {}): any => {
    setModlaMessage("Processsing...");
    setShowModal(true);
    calcPayment();

    const request: any = {
      luckyNumberRecords: luckyNumberRecords,
      knightAssigned: knightAssigned,
      paymentType: paymentType,
      paymentSourceId: paymentInfo.token,
      paymentAmount: totalPayment,
      paymentInfo: paymentInfo,
      quantity: quantity,
      deliveryFees: knightPaymentType ? 0 : deliveryFees,
      coverFees: coverFees
    }

    console.log("populatePurchaseRequest", request);
    luckyNumberService.purchase(request).then((resp: any) => {
      if (resp && resp.success === true) {
        setModlaMessage(`Purchase was Successful! Thank you so much for your purchase.${knightPaymentType ? "" : "\n\nYou calendar will be delivered to you in the next 10 days"}`);
      } else {
        console.log("Payment Error Response", resp);
        setModlaMessage("An error occurred during processing. You can return to the Purchase Screen and try again.");
      }
    })
  };

  const validatePhone = (index: number, val: string) => {
    let phone: string = val;
    if (phone) {
      phone = phone.replaceAll(/[^0-9]/g, "");
      if (phone.length == 10) {
        phone = `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6, 10)}`
      }
    }

    const rec = luckyNumberRecords[index];
    rec.mobilePhone = phone;
    updateItem(rec);
  };

  const quantityChanged = (val: string) => {
    const count = luckyNumberRecords?.length || 0;
    const newCount = val ? +val : 1;

    console.log("quantityChanged", count, newCount, val);

    if (newCount == count) {
      //Do Nothing
    } else if (newCount > count) {
      for (let i = count + 1; i <= newCount; i++) {
        luckyNumberRecords.push(new LuckyNumber(i));
      }
    } else if (newCount < count) {
      for (let i = count; i > newCount; i--) {
        luckyNumberRecords.pop();
      }
    }

    luckyNumberRecords.forEach((lr: LuckyNumber) => {
      console.log(`LuckNumber`, lr.key, lr.firstName, lr.lastName)
    } );

    setLuckyNumberRecords(luckyNumberRecords);
    setQuantity(newCount);
    calcPayment(newCount);
  }

  const handlePaymentType = (val: string) => {
    let covFee: boolean = coverFees || false;
    if (val === "Knight-Cash") {
      setCashType("CASH");
      setKnightAssigned("");
      setCalendarIdValid(false);
      covFee = false;
    } else if (val === "Knight-Event") {
      setCashType("CASH");
      setKnightAssigned("Knight-Event");
      setCalendarIdValid(false);
      covFee = false;
    } else {
      setCashType("");
      setKnightAssigned("");
    }
    setCoverFees(covFee)
    setPaymentType(val);
    calcPayment(quantity, covFee, val, deliverToOwner1 || false);
  }

  return (

    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Lucky Number Calendar</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          {modalMessage?.includes("error") || modalMessage?.includes("exception") && <Button variant="secondary" onClick={handleClose}>
            Return to Purchase Screen
          </Button>}
          <Button variant="primary" href={knightPaymentType === true ? `/purchasecalendar?type=${paymentType}` : "/"} disabled={modalMessage?.startsWith("Processing")}>
            All Done
          </Button>
        </Modal.Footer>
      </Modal>

      <Container>
        <Row className="p-2" flex>
          <Col md="8" className="gap-2">
            <h2 className="d-flex justify-content-center">Purchase Lucky Number Calendar</h2><br />
            <Form>
              <Container>

                <Row>

                  <Col md={4} className='gap-2'>
                    <Form.Group className="mb-1" controlId="purchaseForm.sa">
                      <Form.Label><b>How many calendars?</b></Form.Label>
                      <Form.Select value={quantity} onChange={(e) => { quantityChanged(e.target.value); }}>
                        <option value="1">1 Calendar (${calendarPrice * 1})</option>
                        <option value="2">2 Calendars (${calendarPrice * 2})</option>
                        <option value="3">3 Calendars (${calendarPrice * 3})</option>
                        <option value="4">4 Calendars (${calendarPrice * 4})</option>
                        <option value="5">5 Calendar (${calendarPrice * 5})</option>
                        <option value="6">6 Calendars (${calendarPrice * 6})</option>
                        <option value="7">7 Calendars (${calendarPrice * 7})</option>
                        <option value="8">8 Calendars (${calendarPrice * 8})</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={8}>
                    <Form.Group className="mb-1" controlId="purchaseForm.sa">
                      <Form.Label>&nbsp;</Form.Label>
                      <p className='text-primary'><b>* Makes a great gift with chances to win all year long!</b>  {validated}</p>
                    </Form.Group>

                  </Col>
                </Row>

                <Row>
                  <Col md={12} className='gap-2'>
                    {luckyNumberRecords.map((rec: LuckyNumber, index) => (
                      <div>
                        <br />
                        <Card>

                          <Card.Body>
                            <Row>
                              <Col md={6}>
                                <Form.Group className="mb-1" controlId={`purchaseForm.fn${index}`}>
                                  <Form.Label><b>* Name of Calendar Owner#{rec.key}</b></Form.Label>
                                  <Form.Control required minLength={2} maxLength={100} value={rec.firstName} onChange={(e) => { rec.firstName = e.target.value; updateItem(rec); }} type="text" placeholder="First Name" autoComplete="off" isInvalid={firstNameValid === false} onBlur={(e) => { setFirstNameValid(e.target?.checkValidity() === true); }} />
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group className="mb-1" controlId={`purchaseForm.ln${index}`}>
                                  <Form.Label className="d-none d-md-block">
                                    {index > 0 && <Button variant="outline-primary" size="sm" onClick={() => copyAddress(index - 1, index)}>Copy from Address Above</Button>}&nbsp;
                                  </Form.Label>
                                  <Form.Control required minLength={2} maxLength={100} value={rec.lastName} onChange={(e) => { rec.lastName = e.target.value; updateItem(rec); }} type="text" placeholder="Last Name" autoComplete="off" isInvalid={lastNameValid === false} onBlur={(e) => { setLastNameValid(e.target?.checkValidity() === true); }} />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Form.Group className="mb-1" controlId={`purchaseForm.em${index}`}>
                                  <Form.Control required minLength={2} maxLength={255} value={rec.emailAddress} onChange={(e) => { rec.emailAddress = e.target.value; updateItem(rec); }} type="email" placeholder="email@email.com" autoComplete="off" isInvalid={emailValid === false} onBlur={(e) => { setEmailValid(e.target?.checkValidity() === true); }} />
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group className="mb-1" controlId={`purchaseForm.phone${index}`}>
                                  <Form.Control required min={10} max={20} value={rec.mobilePhone} onChange={(e) => { rec.mobilePhone = e.target.value; updateItem(rec); }} onBlur={(e) => { validatePhone(index, e.target.value); setMobilePhoneValid(e.target?.checkValidity() === true); }} type="text" placeholder="Phone [ 000-000-0000 ]" autoComplete="off" isInvalid={mobilePhoneValid === false} />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} className='gap-2'>
                                <Form.Group className="mb-1" controlId={`purchaseForm.sa${index}`}>
                                  <Form.Label><b>* Address of Calendar Owner#{rec.key} to send the winnings to if number is picked</b></Form.Label>
                                  <Form.Control required minLength={2} maxLength={255} value={rec.streetAddress} onChange={(e) => { rec.streetAddress = e.target.value; updateItem(rec); }} type="text" placeholder="Mailing Address" autoComplete="off" isInvalid={streetAddressValid === false} onBlur={(e) => { setStreetAddressValid(e.target?.checkValidity() === true); }} />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Form.Group controlId={`purchaseForm.ct${index}`}>
                                  <Form.Control required minLength={2} maxLength={100} value={rec.city} onChange={(e) => { rec.city = e.target.value; updateItem(rec); }} type="text" placeholder="City" isInvalid={cityValid === false} onBlur={(e) => { setCityValid(e.target?.checkValidity() === true); }} />
                                </Form.Group>
                              </Col>
                              <Col md={2}>
                                <Form.Group controlId={`purchaseForm.st${index}`}>
                                  <Form.Control required minLength={2} maxLength={2} value={rec.state} onChange={(e) => { rec.state = e.target.value; updateItem(rec); }} type="text" placeholder="State" isInvalid={stateValid === false} onBlur={(e) => { setStateValid(e.target?.checkValidity() === true); }} />
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group controlId={`purchaseForm.pc${index}`}>
                                  <Form.Control required minLength={5} maxLength={10} value={rec.postalCode} onChange={(e) => { rec.postalCode = e.target.value; updateItem(rec) }} type="text" placeholder="Postal Code" isInvalid={postalCodeValid === false} onBlur={(e) => { setPostalCodeValid(e.target?.checkValidity() === true); calcPayment(); }} />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>
                    ))}
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className='gap-2'>
                    <br />
                  </Col>
                </Row>
                {knightPaymentType && <Row>
                  <Col md={12} className='gap-2'>
                    <Form.Group className="mb-1" controlId="purchaseForm.sa">
                      <Form.Label><b>Payment Type</b></Form.Label>

                      <Form.Select value={paymentType} onChange={(e) => { handlePaymentType(e.target.value) }}>
                        <option value="Online">I'm Paying Online with Debit/Credit Card - Deliver Calendar to Address Above</option>
                        <option value="Knight-Cash">I'm Paying by Cash or Check Directly to a Knight in-person. They will hand me the calendar</option>
                        <option value="Knight-Card">I'm Paying with Debit/Credit Card Directly to a Knight in-person. They will hand me the calendar</option>
                        <option value="Knight-Event">I'm at a Knight's Event and paying directly in-person. They will hand me the calendar</option>

                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>}

                {(paymentType === "Online") && (
                  <Row>
                    <Col md={12}>
                      <br />
                      <b>Delivery Cost: {deliveryFees.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                      </b>&nbsp;&nbsp;(Free Delivery to Delaware 43015 zip code, {deliveryFeePrice?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} outside)
                    </Col>
                  </Row>
                )}
                {(paymentType === "Online" && quantity >= 2) && (
                  <Row>
                    <Col md={12}>
                      <br />
                      <InputSwitch checked={deliverToOwner1} onChange={(e) => { calcPayment(quantity, coverFees, paymentType, e.value); }} />
                      &nbsp;&nbsp;Deliver all calendars to the first Address (Owner#1) above. Free delivery<br />
                    </Col>
                  </Row>
                )}


                {(paymentType === "Online" || paymentType === "Knight-Card") && (
                  <Row>
                    <Col md={12}>
                      <InputSwitch checked={coverFees} onChange={(e) => { calcPayment(quantity, e.value); }} />
                      &nbsp;&nbsp;Optional - Help cover card processing fees ({cardFeePrice?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })})<br /><br />
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col md={12}>
                    <b>Total Amount: <span className='text-primary'>{totalPayment?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span></b><br />
                  </Col>
                </Row>

                {(paymentType === "Online" || paymentType === "Knight-Card") && (<Row>
                  <Col md={12}>
                    {isFormValid && <PaymentForm
                      applicationId={squareApplicationId}
                      cardTokenizeResponseReceived={(token, verifiedBuyer) => {
                        console.log('token:', token, 'verifiedBuyer:', verifiedBuyer);

                        const req = populatePurchaseRequest(token);
                      }}
                      locationId={locationId}
                    >
                      <CreditCard />
                      {/* <GooglePay />
                    <ApplePay /> */}
                    </PaymentForm>}
                  </Col>
                </Row>
                )}

                {(paymentType !== "Online") && (
                  <Row>
                    <Row>
                      <Col md={12} className='gap-2'>
                        <br />
                      </Col>
                    </Row>
                    <Col md={4}>
                      <Form.Group controlId={`calendarId-${"1"}`}>
                        {luckyNumberRecords.map((rec: LuckyNumber, index) => (

                          <Form.Control required minLength={3} maxLength={3} value={rec.calendarId} onChange={(e) => { rec.calendarId = e.target.value; updateItem(rec); calcPayment(); }} type="text" placeholder={`Calendar#${rec.key} Num`} isInvalid={calendarIdValid === false} onBlur={(e) => { knightPaymentType && setCalendarIdValid(e.target?.checkValidity() === true); }} />

                        ))}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="purchaseForm.kn">
                        <Form.Control required value={knightAssigned} onChange={(e) => { setKnightAssigned(e.target.value); calcPayment(); }} type="text" placeholder="Knight Last Name" isInvalid={knightAssignedValid === false} onBlur={(e) => { setKnightAssignedValid(knightPaymentType && e.target?.checkValidity() === true); }} />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="purchaseForm.cc">
                        {(paymentType == "Knight-Event" || paymentType == "Knight-Cash") && <Form.Control value={cashType} onChange={(e) => { setCashType(e.target.value); calcPayment(); }} type="text" placeholder="Cash or Check#" />}
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {(paymentType == "Knight-Cash" || paymentType == "Knight-Event") && (
                  <Row>
                    <Row>
                      <Col md={12} className='gap-2'>
                        <br />
                      </Col>
                    </Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <Button variant="outline-primary" disabled={!isFormValid} size="lg" onClick={() => populatePurchaseRequest({ token: "CASH", paymentType: paymentType })}>Purchase Calendar</Button>
                    </Col>
                  </Row>
                )}

              </Container>
              <Row>
                <Col md={12}>
                  &nbsp;
                </Col>
              </Row>
            </Form>

          </Col>
          <Col md="4" className="gap-2 d-flex justify-content-center">
            <Container>
              <Row>
                <Col>
                  <img src={LuckyCalImage} height={200} alt="Lucky Number Calendar" />
                </Col>
              </Row>
              <Row>
                <Col>
                  For questions, please email <br /><a href="mailto://kofc1056.luckycalendar@gmail.com">kofc1056.luckycalendar@gmail.com</a><br /><br />
                </Col>
              </Row>
            </Container>

          </Col>
        </Row>
        <Row>
          <Col md={12}>
            &nbsp;
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            &nbsp;
          </Col>
        </Row>
      </Container>

    </>

  );
}

export default PurchaseLuckyCalendarPage;