
export class LuckyNumber {
    constructor(key: number) {
        this.key = key;
    }

    key: number = 1;
    firstName: string = "";
    lastName: string = "";
    emailAddress: string = "";
    mobilePhone: string = "";
    streetAddress: string = "";
    city: string = "";
    state: string = "OH";
    postalCode: string = "";
    calendarId: string = "";
}
