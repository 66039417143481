import React from 'react';
import LuckyCalImage from '../../images/events/LuckyNumberCalendar.jpg';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function LuckyCalendarPage() {

  // const handleClick = () => {
  //   navigate("/home");
  // }

  return (
    <Container>
      <Row className="p-2" flex>
        <Col md="9" className="gap-2">
          <h1 className="d-flex justify-content-center">2025 Lucky Number Calendar</h1><br />
          <h2 className="d-flex justify-content-start text-primary">How does it Work?</h2>
          <ul className="fs-4 text-primary">
            <li>It works like a raffle that happens every day!</li>
            <li>By purchasing a calendar ($30), you will receive a number ranging from 000 to 999</li>
            <li>Each day of the week in 2025, except Sundays, you have a chance of winning $50 if your number is drawn.</li>
            <li>On a special day each month, you could win $100.</li>
            <li>We will use the evening Ohio Pick 3 number as our random number for the raffle. This ensures that the number is truly random.</li>
            <li>If you win, we will send your money to you within 1 to 2 weeks.</li>
            <li>We only ask for your name, address, email & phone so that we can send you the money if you win. We will not sell or distribute this data.</li>
            <li>Many people give these as gifts. On the purchase screen you'll have the option to provided the name / address for each calendar you purchase. This is where the money will be sent if their lucky number is drawn.</li>
            <li>All of the profits will go to St Mary Catholic Church.</li>
          </ul>
          <br />
          <Link to="/purchasecalendar"><Button variant="primary" size="lg">Purchase Lucky Calendar</Button></Link>
          <br/><br/>
          For questions, please email <a href="mailto://kofc1056.luckycalendar@gmail.com">kofc1056.luckycalendar@gmail.com</a>
        </Col>
        <Col lg="3" className="gap-2">
          <img src={LuckyCalImage} className="img-fluid rounded" alt="Lucky Number Calendar" /><br /><br />
          <Link to="/purchasecalendar"><Button variant="primary" size="lg">Purchase Lucky Calendar</Button></Link>
        </Col>
      </Row>
    </Container>



  );
}

export default LuckyCalendarPage;