
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import logo from '../../images/common/kofc.png';
import { Link } from 'react-router-dom';

const Header = () => (
  <header className="App-header">

    <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
      <Container fluid>
        <Container className="d-flex navigationhead">
          <Link to="/">
            <img src={logo} alt="kofc1056" className="App-logo" />
          </Link>
          &nbsp;Knights of Columbus - Council 1056
        </Container>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-mg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >

            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown title="Donate" id="navbarScrollingDropdown">
              <NavDropdown.Item href="https://kofc1056.square.site/charities">
                <i className="bi bi-credit-card"></i>Donate with Credit/Debit Card
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="https://paypal.me/kofc1056?locale.x=en_US">
                <i className="bi bi-paypal"></i>Donate with PayPal
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Membership" id="navbarScrollingDropdown">
              <NavDropdown.Item href="https://kofc1056.square.site/dues">
                <i className="bi bi-credit-card"></i>Pay Dues with Credit/Debit Card
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Other" id="navbarScrollingDropdown">
              <NavDropdown.Item href="/calendar">
                Lucky Number Calendar
              </NavDropdown.Item>
              <NavDropdown.Item href="/purchasecalendar?type=Knight-Event">
                Lucky Number - Knights
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/events">Events</Nav.Link>
            <Nav.Link href="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Nav.Link>
          </Nav>

        </Navbar.Collapse>

      </Container>
    </Navbar>
  </header>
);

export default Header;