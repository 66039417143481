
import { DateTime } from 'luxon';


class EventsService {

  public async getUpcomingEvents(numberOfEvents: number = 6) {
    const evs = await this.getAllEvents();
    return await evs?.filter((e: { start: DateTime; }) => e.start >  DateTime.local()).slice(0, numberOfEvents); 
  }

  public async getEventsForCalendar() {
    const calendarEvents: any[] = [];

    for (let e of await this.getAllEvents()) {
      calendarEvents.push({
        id: e.id,
        start: e.start?.toJSDate(),
        end: e.end?.toJSDate(),
        allDay: e.allDay,
        title: e.title
      })
    }

    return calendarEvents;
  }

  public async getEventById(eventId: number) {
    const evs = await this.getAllEvents();
    const events = evs?.filter((e) => e.id === eventId);
    return events?.length > 0 ? events[0] : undefined;
  }

  public async getAllEvents(): Promise<any[]> {

    try {

    const res = await fetch("https://api.kofc1056.org/api/events");
    const eventsResponse: any[] = await res.json();
    const events: any[] = [];

    for (let e of eventsResponse) {
      let dtStart = DateTime.fromISO(e.starttime);
      let dtEnd: DateTime = e.endtime ? DateTime.fromISO(e.endtime) : dtStart;
      if (!dtStart.isValid) {
        console.error(`Unable to parse date: ${e.starttime} in events`, e);
      } else {
        dtStart = DateTime.fromISO(e.starttime);
        if (!dtEnd || !dtEnd?.isValid) {
          dtEnd = dtStart;
        }

        const allDay: boolean = dtStart.equals(dtEnd);
        let titleWithTime = e.title;
        if (!allDay && dtStart && dtStart.hour !== 0) {
          titleWithTime = `${titleWithTime} - ${dtStart.toFormat("ha")?.toLowerCase()}`;
        }

        const evt: any = {
          id: e.eventid,
          start: dtStart,
          end: dtEnd,
          allDay: allDay,
          title: titleWithTime,
          fullTitle: e.title,
          href: e.url
        }
        events.push(evt);
      }
    }
    //console.log("Events", events);
    return events;

  } catch (ex) {
    console.error("Exception retrieving events",ex);
    return [];
  }
  }

}

export default EventsService;