import { DateTime, Settings } from 'luxon';
import { useEffect, useState } from 'react';
import { Calendar, luxonLocalizer, Views } from 'react-big-calendar';
import EventsService from '../../services/EventsService';

const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 7 });
const eventsService: EventsService = new EventsService();

function EventsPage(props: any) {

  const [calendarEvents, setCalendarEvents] = useState<any[]>([]);

  const getEvents = async () => {
    const evs = await eventsService.getEventsForCalendar();
    setCalendarEvents(evs);
  }

  const onSelectEvent = async (e: any) => {
    if (e && e.id) {
      const event = await eventsService.getEventById(e.id);
      console.log("Event", event);
      if (event && event.href && event.href?.startsWith("http")) {
        window.open(event.href, "_blank");
      } else if (event && event.href && event.href?.startsWith("/")) {
        window.location = event.href;
      }
    }
  }

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <>
      <h2>Events for Council 1056</h2>

      <Calendar
        localizer={localizer}
        events={calendarEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        views={[Views.MONTH, Views.DAY]}
        defaultView={Views.MONTH}
        onSelectEvent={onSelectEvent}
      />
    </>
  );
}


export default EventsPage;