import { faCalendarDays, faHandshake, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import logo from '../../images/common/kofc.png';
import kofc1056Logo from '../../images/common/KofC1056Logo.jpg';
import donut_banner from '../../images/events/donut_may_baner.png';
import fish_fry from '../../images/events/fish-fry.png';
import golf_outing from '../../images/events/GolfOuting.png';
import purse_banner from '../../images/events/PurseBingo.png';
import festival_banner from '../../images/events/festival_baner.png';
import fourthjuly_banner from '../../images/events/fourth_of_july.png';
import LuckyCalendar from '../../images/events/LuckyNumberCalendarEvent.jpg';
import UpcomingEvents from './UpcomingEvents';

class Home extends React.Component {
  render() {

    return (
      <Container fluid>
        <Row className="p-2">
          <Col xs="12" sm="6" md="8" xl="9" className="gap-2">
            <h3 className="d-flex justify-content-center">Knights of Columbus - Council 1056</h3>
            <h4 className="d-flex justify-content-center">Delaware, Ohio - St. Mary Church</h4>
            <br />
            <Card>
              <Card.Header as="h5">About Our Council</Card.Header>
              <Card.Body>
                <Row>
                  <Col md="2" className="d-none d-md-block">
                    <img src={logo} height={100} alt="kofc1056" />
                  </Col>
                  <Col md="10">
                    Knights of Columbus Delaware, OH Council 1056 is a proud and faithful men’s group promoting Catholic principles within our community and parish for over 100 years. We are Catholic men who lead, serve, protect and defend. We share a desire to be better husbands, fathers, sons, neighbors and role models and to put charity and community first.
                  </Col>

                </Row>

              </Card.Body>
            </Card>

          </Col>
          <Col xs="12" sm="6" md="4" xl="3">
            <Carousel variant="dark" interval={8000} indicators={false}>
              <Carousel.Item>
                <img src={kofc1056Logo} className="img-fluid rounded" alt="KofC 1056" />
              </Carousel.Item>
              <Carousel.Item>
                <Link to="/calendar" className="knight-link">
                  <img src={LuckyCalendar} className="img-fluid rounded" alt="Lucky Number Calendar" />
                </Link>
              </Carousel.Item>

              {/* <Carousel.Item>
                <a href="https://www.tickettailor.com/events/knightsofcolumbuscouncil1056/1317263">
                  <img src={purse_banner} className="img-fluid rounded" alt="Purse Bingo" />
                </a>
              </Carousel.Item> */}
              {/* <Carousel.Item>
                <a href="https://www.tickettailor.com/events/knightsofcolumbuscouncil1056/1066869">
                  <img src={golf_outing} className="img-fluid rounded" alt="Golf Outing 2024" />
                </a>
              </Carousel.Item> */}
              {/* <Carousel.Item>
                <a href="https://signup.com/go/nSHiqGQ">
                  <img src={festival_banner} className="img-fluid rounded" alt="Festival" />
                </a>
              </Carousel.Item> */}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="d-none d-md-block" lg="3" md="4">
            <Link to="/" className="knight-link">
              <div className="p-4 border-light border rounded cc">
                <div className="d-flex justify-content-center align-items-center box-100 mx-auto">
                  <FontAwesomeIcon className="big-icon" icon={faHandshake} />
                </div>
                <h4 className="mb-3 mt-2 d-flex justify-content-center">What We Do</h4>
              </div>
            </Link>
          </Col>
          <Col className="d-none d-md-block" lg="3" md="4">
            <Link to="/events" className="knight-link">
              <div className="p-4 border-light border rounded cc">
                <div className="d-flex justify-content-center align-items-center border rounded-circle box-100 mx-auto">
                  <FontAwesomeIcon className="big-icon" icon={faNewspaper} />
                </div>
                <h4 className="mb-3 mt-2 d-flex justify-content-center">Events</h4>
              </div>
            </Link>
          </Col>
          <Col className="d-none d-md-block" lg="3" md="4">
            <Link to="/calendar" className="knight-link">
              <div className="p-4 border-light border rounded cc">

                <div className="d-flex justify-content-center align-items-center border rounded-circle box-100 mx-auto">
                  <FontAwesomeIcon className="big-icon" icon={faCalendarDays} />
                </div>
                <h4 className="mb-3 mt-2 d-flex justify-content-center">Lucky Calendar</h4>
              </div>
            </Link>
          </Col>
          <Col sm="12" lg="3" md="4">
            <br />
            <UpcomingEvents />
          </Col>
        </Row>
      </Container >
    );
  }
}

export default Home;