
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EventsService from '../../services/EventsService';



function UpcomingEvents(props: any) {

  const eventsService: EventsService = new EventsService();
  const [events, setEvents] = useState<any[]>([]);


  const getEvents = async() =>  {
    console.log("UpcomingEvents Before - getEvents");
    const evs = await eventsService.getUpcomingEvents(6);
    console.log("UpcomingEvents - getEvents", evs);
    setEvents(evs);
  }

  useEffect(() => {
    console.log("Upcoming Events", events);
    getEvents();
  }, []);

  return (

    <Card>
      <Card.Header as="h5">Upcoming Events</Card.Header>
      <Card.Body>
        <div>
          {
            events.map((e, i) => (
              <li className="d-flex align-items-start knight-link" key={e.id}>
                {(e.href?.startsWith("/"))
                  ? <Link to={e.href} className="knight-link">{`${e.start?.toFormat("LLL dd")} - ${e.title}`}</Link>
                  : <a href={e.href} className="knight-link">{`${e.start?.toFormat("LLL dd")} - ${e.title}`}</a>
                }
              </li>
            ))
          }
        </div>
      </Card.Body>
    </Card>

  );
}

export default UpcomingEvents;